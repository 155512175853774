<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    v-if="userId && admin"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Boxes
            </div>
          </template>
          <v-bottom-navigation
            :value="activeBtn"
            grow
            active-class="activatedBtn"
          >
            <v-btn @click="day = 1">
              <span style="font-size:15px">Lundi 05 Octobre</span>
            </v-btn>

            <v-btn @click="day = 2">
              <span style="font-size:15px">Mardi 06 Octobre</span>
            </v-btn>

            <v-btn @click="day = 3">
              <span style="font-size:15px">Mercredi 07 Octobre</span>
            </v-btn>
          </v-bottom-navigation>
          <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <p>Taux de remplissage global : {{ formatNumber(consultations.filter(function(item){ return item.USR_ID != 0 }).length/consultations.length * 100)}}%</p>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <p>Remplissage global : {{ consultations.filter(function(item){ return item.USR_ID != 0 }).length }}/{{ consultations.length }}</p>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <p>Taux de remplissage du jour choisi : {{ formatNumber(
                consultations.filter(function(item){ return item.Date === days[day] && item.USR_ID != 0 }).length / 
                consultations.filter(function(item){ return item.Date === days[day]}).length * 100)}}%
                </p>
             </v-col>
             <v-col
                cols="12"
                md="6"
              >
                <p>Remplissage du jour choisi: {{ consultations.filter(function(item){ return item.Date === days[day] && item.USR_ID != 0 }).length }}/
                                 {{ consultations.filter(function(item){ return item.Date === days[day]}).length }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          </v-card>
          <v-card-text>
            <v-row>
              <v-col
                v-for="boxe in boxes.filter(function(boxe){ return consultations.filter(function(item){ return item.Date === days[day] && item.FBX_ID === boxe.id  }).length })"
                :key="boxe.id"
                cols="3"
                md="2"
              >
                <v-card
                  class="mx-auto my-12"
                  max-width="374"
                >
                  <v-card-title>
                    {{ boxe.name }}
                  </v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text
                    v-for="consultation in consultations.filter(function(item){ return item.Date === days[day] && item.FBX_ID === boxe.id })"
                    :key="consultation.id"
                  >
                  <h3>{{ consultation.FFD_Name }}</h3>
                  <v-divider class="mx-4"></v-divider>
                    {{ consultation.TimeStart.split(':')[0] }}h{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}h{{ consultation.TimeEnd.split(':')[1] }}
                    <p>Maître {{ consultation.AVO_Name }}</p>
                  <p v-if="consultation.USR_ID == 0" style="color:green">Aucune réservation</p>
                  <p v-else style="color:grey">{{ consultation.USR_Name }} {{ consultation.USR_Surname }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { httpGet } from '@/plugins/axios'
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        day: 1,
        days: { 1: '2020-10-05', 2: '2020-10-06', 3: '2020-10-07' },
        modal: false,
        userId: null,
        activeBtn: 0,
        admin: null,
        consultations: null,
        boxes: [],
      }
    },
    mounted () {
      this.userId = this.$store.state.userId
      this.admin = this.$store.state.isAdmin
      this.getBoxes()
      this.getConsultations()
    },
    methods: {
      formatNumber (number) {
        return number.toFixed(2)
      },
      async getBoxes () {
        const response = await httpGet('getBoxes')
        this.boxes = []
        response.boxes.forEach(boxe => {
          this.boxes.push({ id: boxe.FBX_ID, name: boxe.FBX_Name })
        })
      },
      async getConsultations () {
        const consultations = await httpGet('getConsultations')
        this.consultations = consultations.Consultations
      },
    },

  }
</script>
